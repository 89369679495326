@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');



.navbar-2 {
    display: flex;
    flex-direction: row;
    position: fixed;
    width : 100%;
    justify-content: center;
    align-items: space-between;
    background-color: transparent;

    /* animation: fadeInAnimation ease 3s; */

    transition: opacity 0.35s;
    z-index: 4;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.item-title {

    font-size: 1.1rem;
    font-weight: 500;
    color: black;
    /* background-color: white; */
    margin-top: 16px;
    margin-left: 16px;
    /* border: 1px solid black; */
    border: 0px;
    
     /* mix-blend-mode:difference; */
    transition:all;
    transition-duration: 0.35s;
    flex-grow:1;

    &:hover {   
        font-size: 1.2rem;
    }
}   

.navbar-2-item {

    font-size: 2vw;
    font-weight: 700;
    color: black;
    /* background-color: white; */
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 16px;
    /* border: 1px solid black; */
    border: 0px;
    
     /* mix-blend-mode:difference; */
    transition:all;
    transition-duration: 0.35s;

    cursor:pointer;

    -webkit-text-stroke: 1px transparent;
    border : 1px solid transparent;

    &:hover {   
        /* font-size: 1.25rem; */
        /* -webkit-text-stroke: 1px white;
        color: transparent; */
        border-bottom: 1px solid black;
        
        /* color: white; */
    }
}

/* DESKTOP */
@media only screen and (min-width : 720px) {
    .navbar-2 {
        display: flex;
        flex-direction: row;
        position: fixed;
        width : 100%;
        justify-content: left;
        align-items: space-between;
        background-color: transparent;
    
        /* animation: fadeInAnimation ease 3s; */
    
        transition: opacity 0.35s;
        z-index: 4;
    }
    
    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
    
        100% {
            opacity: 1;
        }
    }
    
    .item-title {
    
        font-size: 1.1rem;
        font-weight: 500;
        color: black;
        /* background-color: white; */
        margin-top: 16px;
        margin-left: 16px;
        /* border: 1px solid black; */
        border: 0px;
        
         /* mix-blend-mode:difference; */
        transition:all;
        transition-duration: 0.35s;
        flex-grow:1;
    
        &:hover {   
            font-size: 1.2rem;
        }
    }   
    
    .navbar-2-item {
    
        font-size: 0.833rem;
        font-weight: 700;
        color: black;
        /* background-color: white; */
        margin-top: 8px;
        margin-left: 16px;
        margin-right: 32px;
        /* border: 1px solid black; */
        border: 0px;
        
         /* mix-blend-mode:difference; */
        transition:all;
        transition-duration: 0.35s;
    
        cursor:pointer;
    
        -webkit-text-stroke: 1px transparent;
        border : 1px solid transparent;
    
        &:hover {   
            /* font-size: 1.25rem; */
            /* -webkit-text-stroke: 1px white;
            color: transparent; */
            border-bottom: 1px solid black;
            
            /* color: white; */
        }
    }
    
}
