.projects {
    width : 100vw;
    z-index: 3;

    min-height: 100vh;
    height: fit-content;

    color: black;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.projects-title {
    font-size: 1.3rem;
    font-optical-sizing: normal;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.projects-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100vw;
    max-width: 100%;
    flex-wrap: wrap;
    /* border: 1px solid purple; */
}

.project {
    /* margin: auto; */
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 50vw;
    height: 50vh; */
}


.project-image {
    /* margin: auto; */
    width: 640px;
    height: 360px;

    cursor: pointer;
}

/* Desktop */
@media screen and (min-width : 720px) {
    /* .projects-title {
        font-size: 2rem;
    } */

    .project-image {
        width: 42vw;
        height: 42vh;
        cursor:pointer;
    }
}

@media screen and (landscape: portrait) {
    .projects-container {
        background-color: red;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        max-width: 100%;
        flex-wrap: wrap;
        /* border: 1px solid purple; */
    }
}
