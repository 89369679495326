@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&display=swap");


/* MOBILE */
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    /* border: 1px solid white; */

    z-index: 3;

    color: black;
    /* opacity: 0; */
}



.container {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
}

.name {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 6vw;
    font-weight: 700;
    overflow-wrap: break-word;
    /* white-space: break-space; */
    /* color: white; */
    /* animation: fadeInAnimation ease 3s; */
}

.navbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 128px; */
    color : black;
}

.item {
    font-size: 1vw;
    font-weight: 700;
    /* color: white; */
    margin-top: 8px;
    /* margin-left: 16px;
    margin-right: 32px; */
    border: 0px;
    transition: all;
    transition-duration: 0.35s;
}

.item-button {
    font-family: "Libre Baskerville", serif;
    font-size: 3vw;
    font-weight: 700;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    color: black;

    margin: 0;
    padding: 0;

    border: 1px solid transparent;

    &:hover {
        border-bottom: 1px solid black;
    }

    transition: all 0.35s;
}

/* Desktop */
@media only screen and (min-width : 720px) {
    .name {
        display: flex;
        flex-direction: row;
        font-size: 3vw;
        font-weight: 700;
        align-content: center;
    }

    .item-button {
        font-family: "Libre Baskerville", serif;
        font-size: 0.833rem;
        font-weight: 700;
        background-color: transparent;
        border: 0px;
        cursor: pointer;
        color: black;
    
        margin: 0;
        padding: 0;
    
        border: 1px solid transparent;
    
        &:hover {
            border-bottom: 1px solid black;
        }
    
        transition: all 0.35s;
    }
}


