@import url("https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&display=swap");

.about {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    z-index: 3;

    /* background-color: white; */
    /* border: 1px solid white; */

    color: black;
}

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */
    width: 100%;
    /* padding: 0px 64px 0px 64px; */
}

.about-title {
    text-align: center;
    font-size: 5vw;
    font-weight: 700;
    width: 50%;
    margin: 0 0 16px 0;
    /* animation: fadeInAnimation ease 3s; */

    /* background-color: white; */
}

.about-text {
    text-align:center;
    font-size: 3.0vw;
    font-weight: 700;
    /* color: black; */
    background-color: transparent;
    min-width: 256px;
    width: 50%;

    /* animation: fadeInAnimation ease 3s; */
}

/* DESKTOP */
@media only screen and (min-width: 720px) {
    .about {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        z-index: 3;

        /* background-color: white; */
        /* border: 1px solid white; */

        color: black;
    }

    .about-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* border: 1px solid black; */
        width: 50%;
        /* padding: 0px 64px 0px 64px; */
    }

    .about-title {
        text-align:left;
        font-size: 1.5rem;
        font-weight: 700;
        width: 50%;
        margin: 0 0 16px 0;
        /* animation: fadeInAnimation ease 3s; */

        /* background-color: white; */
    }

    .about-text {
        text-align: left;
        font-size: 0.95rem;
        font-weight: 700;
        /* color: black; */
        background-color: transparent;
        min-width: 256px;
        width: 50%;

        /* animation: fadeInAnimation ease 3s; */
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
