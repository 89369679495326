

.App {
    width: 100vw;
    height: 300vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}
